import _jquery from "jquery";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**!
 * easy-pie-chart
 * Lightweight plugin to render simple, animated and retina optimized pie charts
 *
 * @license 
 * @author Robert Fleischmann <rendro87@gmail.com> (http://robert-fleischmann.de)
 * @version 2.1.7
 **/
!function (a, b) {
  exports = b(_jquery);
}(exports, function (a) {
  var b = function (a, b) {
    var c,
        d = document.createElement("canvas");
    a.appendChild(d), "object" == typeof G_vmlCanvasManager && G_vmlCanvasManager.initElement(d);
    var e = d.getContext("2d");
    d.width = d.height = b.size;
    var f = 1;
    window.devicePixelRatio > 1 && (f = window.devicePixelRatio, d.style.width = d.style.height = [b.size, "px"].join(""), d.width = d.height = b.size * f, e.scale(f, f)), e.translate(b.size / 2, b.size / 2), e.rotate((-0.5 + b.rotate / 180) * Math.PI);
    var g = (b.size - b.lineWidth) / 2;
    b.scaleColor && b.scaleLength && (g -= b.scaleLength + 2), Date.now = Date.now || function () {
      return +new Date();
    };

    var h = function (a, b, c) {
      c = Math.min(Math.max(-1, c || 0), 1);
      var d = 0 >= c ? !0 : !1;
      e.beginPath(), e.arc(0, 0, g, 0, 2 * Math.PI * c, d), e.strokeStyle = a, e.lineWidth = b, e.stroke();
    },
        i = function () {
      var a, c;
      e.lineWidth = 1, e.fillStyle = b.scaleColor, e.save();

      for (var d = 24; d > 0; --d) d % 6 === 0 ? (c = b.scaleLength, a = 0) : (c = 0.6 * b.scaleLength, a = b.scaleLength - c), e.fillRect(-b.size / 2 + a, 0, c, 1), e.rotate(Math.PI / 12);

      e.restore();
    },
        j = function () {
      return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (a) {
        window.setTimeout(a, 1000 / 60);
      };
    }(),
        k = function () {
      b.scaleColor && i(), b.trackColor && h(b.trackColor, b.trackWidth || b.lineWidth, 1);
    };

    (this || _global).getCanvas = function () {
      return d;
    }, (this || _global).getCtx = function () {
      return e;
    }, (this || _global).clear = function () {
      e.clearRect(b.size / -2, b.size / -2, b.size, b.size);
    }, (this || _global).draw = function (a) {
      b.scaleColor || b.trackColor ? e.getImageData && e.putImageData ? c ? e.putImageData(c, 0, 0) : (k(), c = e.getImageData(0, 0, b.size * f, b.size * f)) : (this.clear(), k()) : this.clear(), e.lineCap = b.lineCap;
      var d;
      d = "function" == typeof b.barColor ? b.barColor(a) : b.barColor, h(d, b.lineWidth, a / 100);
    }.bind(this || _global), (this || _global).animate = function (a, c) {
      var d = Date.now();
      b.onStart(a, c);

      var e = function () {
        var f = Math.min(Date.now() - d, b.animate.duration),
            g = b.easing(this || _global, f, a, c - a, b.animate.duration);
        this.draw(g), b.onStep(a, c, g), f >= b.animate.duration ? b.onStop(a, c) : j(e);
      }.bind(this || _global);

      j(e);
    }.bind(this || _global);
  },
      c = function (a, c) {
    var d = {
      barColor: "#ef1e25",
      trackColor: "#f9f9f9",
      scaleColor: "#dfe0e0",
      scaleLength: 5,
      lineCap: "round",
      lineWidth: 3,
      trackWidth: void 0,
      size: 110,
      rotate: 0,
      animate: {
        duration: 1000,
        enabled: !0
      },
      easing: function (a, b, c, d, e) {
        return b /= e / 2, 1 > b ? d / 2 * b * b + c : -d / 2 * (--b * (b - 2) - 1) + c;
      },
      onStart: function (a, b) {},
      onStep: function (a, b, c) {},
      onStop: function (a, b) {}
    };
    if ("undefined" != typeof b) d.renderer = b;else {
      if ("undefined" == typeof SVGRenderer) throw new Error("Please load either the SVG- or the CanvasRenderer");
      d.renderer = SVGRenderer;
    }

    var e = {},
        f = 0,
        g = function () {
      (this || _global).el = a, (this || _global).options = e;

      for (var b in d) d.hasOwnProperty(b) && (e[b] = c && "undefined" != typeof c[b] ? c[b] : d[b], "function" == typeof e[b] && (e[b] = e[b].bind(this || _global)));

      "string" == typeof e.easing && "undefined" != typeof jQuery && jQuery.isFunction(jQuery.easing[e.easing]) ? e.easing = jQuery.easing[e.easing] : e.easing = d.easing, "number" == typeof e.animate && (e.animate = {
        duration: e.animate,
        enabled: !0
      }), "boolean" != typeof e.animate || e.animate || (e.animate = {
        duration: 1000,
        enabled: e.animate
      }), (this || _global).renderer = new e.renderer(a, e), (this || _global).renderer.draw(f), a.dataset && a.dataset.percent ? this.update(parseFloat(a.dataset.percent)) : a.getAttribute && a.getAttribute("data-percent") && this.update(parseFloat(a.getAttribute("data-percent")));
    }.bind(this || _global);

    (this || _global).update = function (a) {
      return a = parseFloat(a), e.animate.enabled ? (this || _global).renderer.animate(f, a) : (this || _global).renderer.draw(a), f = a, this || _global;
    }.bind(this || _global), (this || _global).disableAnimation = function () {
      return e.animate.enabled = !1, this || _global;
    }, (this || _global).enableAnimation = function () {
      return e.animate.enabled = !0, this || _global;
    }, g();
  };

  a.fn.easyPieChart = function (b) {
    return this.each(function () {
      var d;
      a.data(this || _global, "easyPieChart") || (d = a.extend({}, b, a(this || _global).data()), a.data(this || _global, "easyPieChart", new c(this || _global, d)));
    });
  };
});
export default exports;